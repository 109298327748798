/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Main entry point to the application.
 * Sets up Apollo Client and renders React in the
 * browser.
 */

// Styles
import "./styles/index.scss";

// AppRouter (entrypoint)
import AppRouter from "./routers/AppRouter";

// React
import React, { Suspense } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as ReactDOMClient from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// MobX & State
import { Provider } from "mobx-react";
// import { useRootStore } from './store';
import { setupRootStore } from "./state/setup";

// Apollo
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/client";

// Initialize Sentry
import "./sentry";

// Helmet
import { HelmetProvider } from "react-helmet-async";

// I18N
import "./utils/i18n";

import Loader from "./components/Loader";

// Needed for Cache Busting and debugging
import packageJson from "../package.json";

import { configureAbly } from "@ably-labs/react-hooks";
configureAbly({ key: process.env.REACT_APP_ABLY_API_KEY });

global.appVersion = packageJson.version;

// Setup the Root MST store
const rootStore = setupRootStore();

const root = ReactDOMClient.createRoot(document.getElementById("root"));

// Render React
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <HelmetProvider>
          <Provider rootStore={rootStore}>
            <ApolloProvider client={client}>
              <AppRouter />
            </ApolloProvider>
          </Provider>
        </HelmetProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
