/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a ListItemBrokerInfo model to be used
 * by MST within the store.
 */

import { types } from "mobx-state-tree";

const ListItemBrokerInfo = types
  .model("ListItemBrokerInfo", {
    _id: "",
    phone: "",
    email: "",
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultListItemBrokerInfoState = {
  _id: "",
  phone: "",
  email: "",
};

export default ListItemBrokerInfo;
