/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Chatroom model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import Message from "./message";
import User from "./user";

const Chatroom = types
  .model("Chatroom", {
    _id: "",
    name: "",
    participants: types.maybeNull(
      types.array(
        types.model({
          _id: "",
          name: "",
          privileges: "",
        }),
      ),
    ),
    admins: types.maybeNull(
      types.array(
        types.model({
          _id: "",
          name: "",
          privileges: "",
        }),
      ),
    ),
    messages: types.maybeNull(types.array(Message)),
    lastReadDate: types.maybeNull(types.Date),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultChatroomState = {
  _id: "",
  name: "",
  participants: [],
  admins: [],
  messages: [],
  lastReadDate: new Date(),
};

export default Chatroom;
