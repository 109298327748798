/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Coordinates model to be used
 * by MST within the store.
 */

import { types } from "mobx-state-tree";

const MapImageUrl = types.model("MapImageUrl", {
  bg: "",
  en: "",
  gr: "",
});

export const defaultMapImageUrlState = {
  bg: "",
  en: "",
  gr: "",
};

export default MapImageUrl;
