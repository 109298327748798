import { useEffect } from "react";

const URL = "https://upload-widget.cloudinary.com/global/all.js";

export const useCloudinaryScript = () => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", URL);
    head.appendChild(script);
  }, [URL]);
};
