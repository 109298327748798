// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

// Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  maxValueLength: 10000,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring (in local) and only 50% in production.
  tracesSampleRate: process.env.REACT_APP_LOCAL ? 1.0 : 0.5,
  replaysSessionSampleRate: process.env.REACT_APP_LOCAL ? 1 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
