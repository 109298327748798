/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines all front-end constants.
 */
export const SESSION_TOKEN = "sessionToken";
export const REFRESH_TOKEN = "refreshToken";
export const ITEMS_PER_PAGE = 15;
export const MAX_META_DESCRIPTION_LENGTH = 124;
export const INTERNAL_SERVER_ERR = "INTERNAL_SERVER_ERROR";
export const ADMIN_PANEL_LISTING_FORM = "ADMIN_PANEL_LISTING_FORM";
export const DEFAULT_AVATAR =
  "https://res.cloudinary.com/drtutht80/image/upload/v1702758120/amica-avatars/default_avatar.webp";
export const LOGIN_PAGE_IMAGE =
  "https://res.cloudinary.com/drtutht80/image/upload/f_auto/v1675011934/amica-others/login.jpg";
export const APP_ROOT = window.location.hostname;

export const PREFFERED_LANG_KEY = "i18nextLng";

export const ADMIN_MESSAGE_PATTERN = "ADMIN_MSG - ";

export const ARRAY_TYPE_FIELDS = [
  "keywords",
  "specifics",
  "propertyTypes",
  "propertySubTypes",
  "regions",
  "locations",
  "subLocations",
  "countries",
  "buildingMaterials",
  "details",
];

export const NUMBER_LISTING_FIELDS = [
  "area",
  "bathrooms",
  "bedrooms",
  "floor",
  "floors",
  "garages",
  "yardArea",
  "yearBuilt",
];

export const BOOLEAN_TYPE_FIELDS = [
  "noFirstFloor",
  "onlyFirstFloor",
  "noLastFloor",
  "onlyLastFloor",
  "displayOnHomepage",
];

export const FLOAT_TYPE_FIELDS = [
  "minPrice",
  "maxPrice",
  "minArea",
  "maxArea",
  "minYardArea",
  "maxYardArea",
];

export const INT_TYPE_FIELDS = [
  "minFloor",
  "maxFloor",
  "minFloors",
  "maxFloors",
  "minYearBuilt",
  "maxYearBuilt",
];

// Options for certain static fields
export const LISTING_TYPE_OPTIONS = [
  { value: "sale", label: "Продажби" },
  { value: "rent", label: "Наеми" },
];

export const LISTING_TYPE_OPTIONS_EN = [
  { value: "sale", label: "Sale" },
  { value: "rent", label: "Rent" },
];

export const LISTING_TYPE_OPTIONS_GR = [
  { value: "sale", label: "Πώληση" },
  { value: "rent", label: "Ενοικίαση" },
];

export const MATERIAL_OPTIONS = [
  { value: "Тухла", label: "Тухла" },
  { value: "Панел", label: "Панел" },
  { value: "Гредоред", label: "Гредоред" },
  { value: "ЕПК", label: "ЕПК" },
  { value: "ПК", label: "ПК" },
  { value: "Сглобяема Конструкция", label: "Сглобяема Конструкция" },
  { value: "", label: "Неизвестно" },
];

export const MATERIAL_OPTIONS_EN = [
  { value: "Тухла", label: "Bricks" },
  { value: "Панел", label: "Panels" },
  { value: "Гредоред", label: "Joists" },
  { value: "ЕПК", label: "CSF" },
  { value: "ПК", label: "CF" },
  { value: "Сглобяема Конструкция", label: "Prefabricated Construction" },
  { value: "", label: "Unknown" },
];
export const MATERIAL_OPTIONS_GR = [
  { value: "Тухла", label: "Τούβλο" },
  { value: "Панел", label: "Πίνακας" },
  { value: "Гредоред", label: "Δοκάρια" },
  { value: "ЕПК", label: "ερπυστικός ξυλότυπος" },
  { value: "ПК", label: "χονδροειδής ξυλότυπος" },
  { value: "Сглобяема Конструкция", label: "Προκατασκευασμένες κατασκευές" },
  { value: "", label: "Άγνωστος" },
];

export const CURRENCY_OPTIONS = [
  { value: "BGN", label: "Лева" },
  { value: "EURO", label: "Евро" },
];
export const CURRENCY_OPTIONS_EN = [
  { value: "BGN", label: "BGN" },
  { value: "EURO", label: "EURO" },
];
export const CURRENCY_OPTIONS_GR = [
  { value: "BGN", label: "Λέβα" },
  { value: "EURO", label: "ευρώ" },
];

export const DETAILS_OPTIONS = [
  { label: "Басейн", value: "Басейн" },
  { label: "В близост до мол", value: "Близо до мол" },
  { label: "Заден двор", value: "Заден двор" },
];
export const DETAILS_OPTIONS_EN = [
  { label: "Pool", value: "Басейн" },
  { label: "Near a mall", value: "Близо до мол" },
  { label: "Backyard", value: "Заден двор" },
];
export const DETAILS_OPTIONS_GR = [
  { label: "Πισίνα", value: "Басейн" },
  { label: "Κοντά σε εμπορικό κέντρο", value: "Близо до мол" },
  { label: "Πίσω αυλή", value: "Заден двор" },
];

export const STATUS_OPTIONS = [
  { value: "for review", label: "Под ревю" },
  { value: "active", label: "Активна" },
  { value: "archived", label: "Архивирана" },
];

export const ORIENTATION_OPTIONS = [
  { label: "Север", value: "Север" },
  { label: "Юг", value: "Юг" },
  { label: "Изток", value: "Изток" },
  { label: "Запад", value: "Запад" },
];
export const ORIENTATION_OPTIONS_EN = [
  { label: "North", value: "Север" },
  { label: "South", value: "Юг" },
  { label: "East", value: "Изток" },
  { label: "West", value: "Запад" },
];
export const ORIENTATION_OPTIONS_GR = [
  { label: "Βόρεια", value: "Север" },
  { label: "Νότια", value: "Юг" },
  { label: "Ανατολικά", value: "Изток" },
  { label: "Δύση", value: "Запад" },
];

export const LANGUAGE_OPTIONS = [
  {
    label: "Български",
    value: "bg",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "Ελληνική",
    value: "gr",
  },
];

export const TYPES_THAT_REQUIRE_YARD_AREA = ["Къща", "Вила"];
export const TYPES_THAT_DO_NOT_REQUIRE_BM_AND_YB = [
  "Парцел",
  "Земеделска земя",
];
// minFloor and maxFloor only, not floors
export const TYPES_THAT_REQUIRE_FLOOR = [
  "1-Стаен",
  "2-Стаен",
  "3-Стаен",
  "4-Стаен",
  "Многостаен",
  "Мезонет",
  "Ателие-Таван",
  "Офис",
  "Етаж от къща",
];

export const LISTING_PATHS_THAT_NEED_TRANSLATION = [
  "description",
  "title",
  "buildingMaterial",
  "propertyType",
  "propertySubType",
  "specifics", // Array
  "details", // Array
  "orientation", // Array
  "fullAddress",
  "region",
  "location",
  "subLocation",
  "country",
];

export const HARDCODED_TEMP_LIMIT = 1000;

export const INTERESTED_IN_OPTIONS = [
  { value: "Неизвестно", label: "Неизвестно" },
  { value: "Купуване", label: "Покупка" },
  { value: "Продажба", label: "Продажба" },
  { value: "Наемане", label: "Наемане" },
];

export const INTERESTED_IN_OPTIONS_EN = [
  { value: "Неизвестно", label: "Undisclosed" },
  { value: "Купуване", label: "Buying" },
  { value: "Продажба", label: "Selling" },
  { value: "Наемане", label: "Renting" },
];

export const INTERESTED_IN_OPTIONS_GR = [
  { value: "Неизвестно", label: "Άγνωστο" },
  { value: "Купуване", label: "Αγοράζοντας" },
  { value: "Продажба", label: "Πώληση" },
  { value: "Наемане", label: "Ενοικίαση" },
];

export const NEW_MESSAGE = "messages/new";
export const MESSAGE_DELETED = "messages/deleted";
export const MESSAGE_EDITED = "messages/edited";
export const USER_STATUS_UPDATED = "userStatus/updated";
export const USER_ADDED = "user/added";
export const USER_LEFT = "user/left";
export const USER_REMOVED = "user/removed";
export const ADMIN_ADDED = "admin/added";
export const ADMIN_REMOVED = "admin/removed";
export const CHATROOM_CREATED = "chatroom/new";
export const CHATROOM_EDITED = "chatroom/edited";
export const CHATROOM_DELETED = "chatroom/deleted";
export const NEW_LISTING = "listing/new";
export const LISTING_UPDATED = "listing/updated";
export const LISTING_DELETED = "listing/deleted";
export const NEW_CLIENT = "client/new";
export const CLIENT_UPDATED = "client/updated";
export const CLIENT_DELETED = "client/deleted";
export const NEW_REGION = "region/new";
export const REGION_UPDATED = "region/updated";
export const REGION_DELETED = "region/deleted";
export const NEW_PROPERTY_TYPE = "propertyType/new";
export const PROPERTY_TYPE_UPDATED = "propertyType/updated";
export const PROPERTY_TYPE_DELETED = "propertyType/deleted";
export const USER_UPDATED = "user/updated";
export const USER_DELETED = "user/deleted";
export const NEW_USER = "user/new";

export const MESSAGES_PER_LOAD = 30;

export const WIDTH_FOR_MOBILE = 1355;
export const WIDTH_FOR_MOBILE_GREEK_SPECIAL_CASE = 1581;

export const LOGO =
  "https://res.cloudinary.com/drtutht80/image/upload/v1699569987/amica-others/logo_vertical.png";

export const SESSION_STORAGE_LANG_KEY = "LANG";
export const SESSION_STORAGE_REGION_CENTRE_KEY = "CURRENT_REGION_CENTRE";
export const SESSION_STORAGE_VIEWED_LISTINGS_KEY =
  "VIEWED_LISTINGS_THIS_SESSION";

export const REGIONS_THAT_SUPPORT_MAP_POLYGONS = [
  "Пловдив Област",
  "Бургас Област",
  "Варна Област",
  "София Област",
  "Пловдив",
  "София",
  "Варна",
  "Бургас",
  "Централна Гърция",
  "Западна Гърция",
  "Източна Гърция",
  "Южна Гърция",
  "Северна Гърция",
];
