/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines the set of mutations
 * which can be used for the user model.
 */

import { gql } from "@apollo/client";

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $email: String!
    $newPassword: String!
    $resetToken: String!
  ) {
    resetPassword(
      email: $email
      newPassword: $newPassword
      resetToken: $resetToken
    )
  }
`;

export const EDIT_SAVED_LISTINGS_MUTATION = gql`
  mutation EditSavedListingsMutation($listingIds: [String!]) {
    editSavedListings(listingIds: $listingIds) {
      savedListings
    }
  }
`;

export const SEND_RESET_EMAIL_MUTATION = gql`
  mutation SendResetEmailMutation($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const TRANSFER_BROKER_LISTINGS_MUTATION = gql`
  mutation TransferBrokerListingsMutation($toId: String!, $fromId: String!) {
    transferBrokerListings(toId: $toId, fromId: $fromId)
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $avatar: String
    $name: String!
    $password: String!
    $email: String!
    $phone: String
    $interestedIn: String!
    $interestTypes: [String!]
    $interestRegions: [String!]
    $interestLocations: [String!]
    $emailPreferences: Boolean!
    $preferredLanguage: String!
  ) {
    signup(
      avatar: $avatar
      name: $name
      password: $password
      email: $email
      phone: $phone
      interestedIn: $interestedIn
      interestTypes: $interestTypes
      interestRegions: $interestRegions
      interestLocations: $interestLocations
      emailPreferences: $emailPreferences
      preferredLanguage: $preferredLanguage
    ) {
      user {
        _id
        avatar
        name
        name_en
        name_gr
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        email
        hasTwoFA
        preferredLanguage
        secretVerified
        privileges
        alerts {
          title
          instance
          frequency
        }
        emailPreferences
        chatActive
        savedListings
        createdAt
      }
      sessionHeaderToken {
        body
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String!
    $password: String!
    $keepSignedIn: Boolean!
  ) {
    login(email: $email, password: $password, keepSignedIn: $keepSignedIn) {
      user {
        _id
        avatar
        name
        name_en
        name_gr
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        email
        hasTwoFA
        preferredLanguage
        secretVerified
        privileges
        alerts {
          title
          instance
          frequency
        }
        emailPreferences
        chatActive
        savedListings
      }
      sessionHeaderToken {
        body
      }
      refreshHeaderToken {
        body
      }
    }
  }
`;

export const VERIFY_SECRET_MUTATION = gql`
  mutation VerifySecretMutation($token: String!) {
    verifySecret(token: $token)
  }
`;

export const VALIDATE_TOKEN_MUTATION = gql`
  mutation ValidateTokenMutation($email: String!, $token: String!) {
    validateToken(email: $email, token: $token)
  }
`;

export const EDIT_USER_MUTATION = gql`
  mutation EditUserMutation(
    $userId: String!
    $email: String
    $password: String
    $newPassword: String
    $name: String
    $phone: String
    $chatActive: Boolean
    $bio: String
    $avatar: String
    $interestedIn: String
    $interestTypes: [String!]
    $interestRegions: [String!]
    $interestLocations: [String!]
    $preferredLanguage: String
  ) {
    editUser(
      userId: $userId
      name: $name
      newPassword: $newPassword
      phone: $phone
      email: $email
      password: $password
      chatActive: $chatActive
      bio: $bio
      interestedIn: $interestedIn
      interestTypes: $interestTypes
      interestRegions: $interestRegions
      interestLocations: $interestLocations
      avatar: $avatar
      preferredLanguage: $preferredLanguage
    ) {
      ... on User {
        _id
        avatar
        name
        name_en
        name_gr
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        email
        hasTwoFA
        preferredLanguage
        secretVerified
        privileges
        alerts {
          title
          instance
          frequency
        }
        emailPreferences
        chatActive
        savedListings
        createdAt
      }
      ... on AuthPayload {
        user {
          _id
          avatar
          name
          name_en
          name_gr
          phone
          interestedIn
          interestTypes
          interestRegions
          interestLocations
          bio
          bio_en
          bio_gr
          email
          hasTwoFA
          preferredLanguage
          secretVerified
          privileges
          alerts {
            title
            instance
            frequency
          }
          emailPreferences
          chatActive
          savedListings
          createdAt
        }
        sessionHeaderToken {
          body
        }
      }
    }
  }
`;

export const DISABLE_TWOFA_MUTATION = gql`
  mutation DisableTwoFAMutation {
    disableTwoFA {
      hasTwoFA
      secretVerified
    }
  }
`;

export const SET_CHAT_ACTIVE_MUTATION = gql`
  mutation SetChatActiveMutation {
    setChatActive {
      chatActive
    }
  }
`;
export const SET_CHAT_INACTIVE_MUTATION = gql`
  mutation SetChatInactiveMutation {
    setChatInactive {
      chatActive
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    logout
  }
`;

export const UPDATE_USER_STATUS_MUTATION = gql`
  mutation UpdateUserStatusMutation($active: Boolean!) {
    updateUserStatus(active: $active) {
      userId
      active
    }
  }
`;

export const EDIT_USER_AS_SATAFF_MUTATION = gql`
  mutation EditUserAsStaffMutation(
    $userId: String!
    $name: String
    $avatar: String
    $email: String
    $phone: String
    $privileges: String
    $interestedIn: String
    $interestTypes: [String!]
    $interestRegions: [String!]
    $interestLocations: [String!]
    $bio: String
  ) {
    editUserAsStaff(
      userId: $userId
      name: $name
      avatar: $avatar
      email: $email
      phone: $phone
      privileges: $privileges
      interestedIn: $interestedIn
      interestTypes: $interestTypes
      interestRegions: $interestRegions
      interestLocations: $interestLocations
      bio: $bio
    ) {
      _id
      avatar
      name
      name_en
      name_gr
      phone
      interestedIn
      interestTypes
      interestRegions
      interestLocations
      bio
      bio_en
      bio_gr
      email
      hasTwoFA
      preferredLanguage
      secretVerified
      privileges
      alerts {
        title
        instance
        frequency
      }
      emailPreferences
      chatActive
      savedListings
      createdAt
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUserMutation($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

export const CLOSE_ACCOUNT_MUTATION = gql`
  mutation CloseAccountMutation(
    $userId: String!
    $password: String!
    $staffAccount: Boolean!
    $successor: String
  ) {
    closeAccount(
      userId: $userId
      password: $password
      staffAccount: $staffAccount
      successor: $successor
    )
  }
`;
