/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a user model to be used
 * by MST within the store.
 */
import { applySnapshot, types } from "mobx-state-tree";
import {
  DEFAULT_AVATAR,
  REFRESH_TOKEN,
  SESSION_TOKEN,
} from "../utils/constants";
import Client from "./client";
import { decrypt, encrypt } from "../utils/utils";

const User = types
  .model("UserModel", {
    _id: "",
    sessionToken: types.maybeNull(
      types.string,
      decrypt(localStorage.getItem(SESSION_TOKEN))
    ),
    avatar: "",
    name: "",
    name_en: "",
    name_gr: "",
    email: "",
    phone: "",
    interestedIn: "",
    interestTypes: types.array(types.string, []),
    interestRegions: types.array(types.string, []),
    interestLocations: types.array(types.string, []),
    bio: types.maybeNull(types.string, ""),
    bio_en: types.maybeNull(types.string, ""),
    bio_gr: types.maybeNull(types.string, ""),
    secretVerified: false,
    emailPreferences: false,
    chatActive: false,
    savedListingIds: types.maybeNull(types.array(types.string, [])),
    alertIds: types.maybeNull(types.array(types.string, [])),
    // searchIds: types.maybeNull(types.array(types.string, [])),
    hasTwoFA: false,
    privileges: "",
    clients: types.maybeNull(types.array(Client)),
    preferredLanguage: types.maybeNull(types.enumeration(["en", "bg", "gr"])),
  })
  .actions((self) => {
    function setUser(user) {
      // If object coming from the db
      if (user.__typename) delete user.__typename; // Remove the unnecessary fields

      if (!user.bio) {
        user.bio = "";
        user.bio_en = "";
        user.bio_gr = "";
      }

      applySnapshot(self, { ...self, ...user });
    }
    function login(loginInfo) {
      const user = {
        ...loginInfo.user,
        sessionToken: loginInfo.sessionHeaderToken.body,
      };
      setUser(user);
    }
    function signup(signupInfo) {
      const user = { ...signupInfo };
      setUser(user);
      localStorage.setItem(SESSION_TOKEN, encrypt(signupInfo.sessionToken));
    }
    function logout() {
      setUser({
        ...defaultUserState,
        sessionToken: "",
      });

      localStorage.clear();

      return true;
    }
    function setAvatar(avatar) {
      applySnapshot(self, { ...self, avatar });
    }
    return {
      setUser,
      login,
      signup,
      logout,
      setAvatar,
    };
  })
  .views((self) => ({}));

export const defaultUserState = {
  _id: "",
  sessionToken: decrypt(localStorage.getItem(SESSION_TOKEN)),
  avatar: DEFAULT_AVATAR,
  name: "",
  name_en: "",
  name_gr: "",
  email: "",
  phone: "",
  interestedIn: "",
  interestTypes: [],
  interestRegions: [],
  interestLocations: [],
  bio: "",
  bio_en: "",
  bio_gr: "",
  secretVerified: false,
  emailPreferences: false,
  chatActive: false,
  savedListingIds: [],
  savedListings: [],
  alertIds: [],
  alerts: [],
  searchIds: [],
  // searches: [],
  hasTwoFA: false,
  privileges: "",
  clients: [],
  preferredLanguage: "bg",
};

export default User;
