/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Helper functions implemented as hooks
 * to be used within the React functional components.
 */

import { useLayoutEffect, useState } from "react";

/* Window size hook which attaches a listener to window
 *  and sends updates to the caller when the window
 * gets resized.
 */
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
