/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a ChatStore model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import Chatroom from "./chatroom";

const ChatStore = types
  .model("ChatStore", {
    chatrooms: types.maybeNull(types.array(Chatroom)),
    currentChatroomId: types.maybeNull(types.string),
    userStatuses: types.maybeNull(
      types.array(
        types.model({
          userId: "",
          active: false,
        }),
      ),
    ),
    unreadMessages: types.maybeNull(types.frozen()),
  })
  .actions((self) => ({
    setChatrooms(chatrooms) {
      applySnapshot(self, { ...self, chatrooms: [...chatrooms] });
    },
    addNewChatroom(chatroom) {
      let messages = chatroom.messages
        ? [
            ...chatroom.messages.map((message) => ({
              chatroomId: chatroom._id,
              content: message.content,
              senderId:
                message.sender && message.sender._id
                  ? message.sender._id
                  : "Deleted User",
              senderName:
                message.sender && message.sender.name
                  ? message.sender.name
                  : "Deleted User",
              _id: message._id,
              createdAt: new Date(message.createdAt),
            })),
          ]
        : [];

      const chatroomToAdd = {
        ...chatroom,
        messages,
        lastReadDate: null, // Only use this action when a new room is created or the user is added for the first time
      };

      applySnapshot(self, {
        ...self,
        chatrooms: [...self.chatrooms, chatroomToAdd],
      });
    },
    updateChatroom(updatedChatroom) {
      applySnapshot(self, {
        ...self,
        chatrooms: [
          ...self.chatrooms.map((chatroom) => {
            if (chatroom._id.toString() === updatedChatroom._id.toString()) {
              chatroom.name = updatedChatroom.name;
            }
            return chatroom;
          }),
        ],
      });
    },
    setCurrentChatroomId(chatroomId) {
      applySnapshot(self, { ...self, currentChatroomId: chatroomId });
    },
    deleteChatroom(chatroomId) {
      applySnapshot(self, {
        ...self,
        chatrooms: [
          ...self.chatrooms.filter(
            (chatroom) => chatroom._id.toString() !== chatroomId.toString(),
          ),
        ],
      });
    },
    removeMessage(chatroomId, messageId) {
      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.messages = [
            ...chatroom.messages.filter(
              (message) => message._id.toString() !== messageId.toString(),
            ),
          ];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    addMessage(chatroomId, newMessage) {
      let messageToAppend = {
        _id: newMessage._id,
        chatroomId,
        content: newMessage.content,
        createdAt: new Date(newMessage.createdAt),
        senderId: newMessage.sender._id,
        senderName: newMessage.sender.name,
      };

      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.messages = [...chatroom.messages, messageToAppend];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    editMessage(chatroomId, editedMessage) {
      let newContent = editedMessage.content;
      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.messages = [
            ...chatroom.messages.map((message) => {
              if (message._id.toString() === editedMessage._id.toString()) {
                message.content = newContent;
              }
              return message;
            }),
          ];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    addParticipant(chatroomId, participant) {
      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.participants = [...chatroom.participants, participant];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    removeParticipant(chatroomId, participant) {
      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.participants = [
            ...chatroom.participants.filter(
              (existing) =>
                existing._id.toString() !== participant._id.toString(),
            ),
          ];
          chatroom.admins = [
            ...chatroom.admins.filter(
              (existing) =>
                existing._id.toString() !== participant._id.toString(),
            ),
          ];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    addChatroomAdmin(chatroomId, admin) {
      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.admins = [...chatroom.admins, admin];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    removeChatroomAdmin(chatroomId, admin) {
      let updatedChatrooms = self.chatrooms.map((chatroom) => {
        if (chatroom._id.toString() === chatroomId.toString()) {
          chatroom.admins = [
            ...chatroom.admins.filter(
              (existing) => existing._id.toString() !== admin._id.toString(),
            ),
          ];
        }
        return chatroom;
      });
      applySnapshot(self, { ...self, chatrooms: [...updatedChatrooms] });
    },
    setUserStatuses(userStatuses) {
      if (userStatuses) {
        applySnapshot(self, { ...self, userStatuses: [...userStatuses] });
      }
    },
    setUnreadMessages(unreadMessages) {
      let updatedUnreadMessages = { ...self.unreadMessages, ...unreadMessages };
      let userChatrooms = self.chatrooms.map((chatroom) => chatroom._id);

      Object.keys(updatedUnreadMessages).forEach((roomId) => {
        if (!userChatrooms.includes(roomId.toString()))
          delete updatedUnreadMessages[roomId];
      });

      applySnapshot(self, {
        ...self,
        unreadMessages: { ...updatedUnreadMessages },
      });
    },
  }))
  .views((self) => ({}));

export const defaultChatStoreState = {
  chatrooms: [],
  currentChatroomId: null,
  userStatuses: [],
  unreadMessages: {},
};

export default ChatStore;
