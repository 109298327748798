/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Region model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import Location from "./location";

const Region = types
  .model("Region", {
    _id: "",
    name: "",
    name_en: "",
    name_gr: "",
    country: "",
    country_en: "",
    country_gr: "",
    locations: types.maybeNull(types.array(Location, [])),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultRegionState = {
  _id: "",
  name: "",
  name_en: "",
  name_gr: "",
  country: "",
  country_en: "",
  country_gr: "",
  locations: [],
};

export default Region;
