import { setContext } from "@apollo/client/link/context";
import { REFRESH_TOKEN, SESSION_TOKEN } from "../../utils/constants";
import { decrypt } from "../../utils/utils";

// Apollo Client link to handle auth (setting headers)
export const authLink = setContext((_, context) => {
  const authHeaderToken = decrypt(localStorage.getItem(SESSION_TOKEN));
  const refreshHeaderToken = decrypt(localStorage.getItem(REFRESH_TOKEN));

  const { headers } = context;

  return {
    ...context,
    headers: {
      ...headers,
      authorization: authHeaderToken ? `Bearer ${authHeaderToken}` : "",
      refresh: refreshHeaderToken ? `Bearer ${refreshHeaderToken}` : "",
    },
  };
});
