// I18Next
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// Automatic language detection
import LanguageDetector from "i18next-browser-languagedetector";

// Allows us to utilize folders (public/locales)
import Backend from "i18next-http-backend";
import { SESSION_STORAGE_LANG_KEY } from "./constants";
import { isNilOrEmpty } from "./utils";

const initLang = isNilOrEmpty(sessionStorage.getItem(SESSION_STORAGE_LANG_KEY))
  ? "bg"
  : sessionStorage.getItem(SESSION_STORAGE_LANG_KEY);

i18next.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  fallbackLng: "bg",
  lng: initLang,
});
