/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Location model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";

const Location = types
  .model("Location", {
    _id: "",
    name: "",
    name_en: "",
    name_gr: "",
    subLocations: types.maybeNull(types.array(types.string, [])),
    subLocations_en: types.maybeNull(types.array(types.string, [])),
    subLocations_gr: types.maybeNull(types.array(types.string, [])),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultLocationState = {
  _id: "",
  name: "",
  name_en: "",
  name_gr: "",
  subLocations: [],
  subLocations_en: [],
  subLocations_gr: [],
};

export default Location;
