/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a PriceDate model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";

const PriceDate = types
  .model("PriceDate", {
    price: types.number,
    currency: "",
    negotiable: false,
    date: types.maybeNull(types.Date),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultPriceDateState = {
  price: -1,
  currency: "",
  negotiable: false,
  date: new Date(),
};

export default PriceDate;
