import { ApolloLink } from "@apollo/client";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { errorLink } from "./links/errorLink";
import { link } from "./links/generalLink";

// Assemble the Apollo Client instance
export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, link]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
