/*
* Author: Kristiyan Doykov
* Last updated: 14/12/2021
* Purpose: Defines the set of queries
* which can be used for the user model.
*/
import { gql } from "@apollo/client";

export const USER_QUERY = gql`
  query GetUser {
    user {
      _id
      avatar
      name
      name_en
      name_gr
      email
      phone
      interestedIn
      interestTypes
      interestRegions
      interestLocations
      bio
      bio_en
      bio_gr
      secretVerified
      emailPreferences
      chatActive
      alerts {
            _id
            title
            instance
            frequency
        }
      savedListings
      hasTwoFA
      preferredLanguage
      privileges
    }
  }
`;

export const USER_QUERY_WITH_QR = gql`
  query GetUser {
    user {
      _id
      avatar
      name
      name_en
      name_gr
      email
      phone
      interestedIn
      interestTypes
      interestRegions
      interestLocations
      bio
      bio_en
      bio_gr
      secretBase32
      secretOtpAuthUrl
      secretVerified
      emailPreferences
      chatActive
      alerts {
            _id
            title
            instance
            frequency
        }
      savedListings
      hasTwoFA
      preferredLanguage
      privileges
    }
  }
`;


export const LOGIN_STATUS_QUERY = gql`
  query LoginStatus {
    loginStatus @client
  }
`;

export const CHECK_AUTH_QUERY = gql`
  query CheckAuth {
    authenticate {
      sessionHeaderToken {
        body
      }
      user {
        _id
        avatar
        name
        name_en
        name_gr 
        email
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        secretVerified
        emailPreferences
        chatActive
        alerts {
            title
            instance
            frequency
        }
        savedListings
        hasTwoFA
        preferredLanguage
        privileges
      }
    }
  }
`;

export const CLOUDINARY_CREDS_QUERY = gql`
  query CloudinaryCredsQuery {
    cloudinaryCreds {
      name
      presetWatermarkTopRight
      presetWatermarkBottomRight
      presetWatermarkBottomLeft
      presetWatermarkTopLeft
      presetAvatars
      folder
      folderListings,
      folderAvatars
    }
  }
`;


export const GET_USER_STATUSES_QUERY = gql`
  query GetUserStatusesQuery($userIds: [String!]) {
    getUserStatuses(userIds: $userIds) {
      userId
      active
    }
  }
`;

export const GET_ALL_ADMINS_AND_BROKERS_QUERY = gql`
  query GetAllAdminsAndBrokersQuery {
    getAllAdminsAndBrokers {
        _id
        avatar
        name
        name_en
        name_gr
        email
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        secretVerified
        emailPreferences
        chatActive
        alerts {
            title
            instance
            frequency
        }
        savedListings
        hasTwoFA
        preferredLanguage
        privileges
    }
  }
`;

export const GET_TEAM_QUERY = gql`
  query GetTeamQuery {
    getAllAdminsAndBrokers {
        _id
        avatar
        name
        name_en
        name_gr
        email
        phone
        bio
        bio_en
        bio_gr
        privileges
    }
  }
`;

export const GET_USERS_QUERY = gql`
  query GetUsersQuery($_id: String, $name: String, $email: String, $phone: String) {
    getUsers(_id: $_id, name: $name, email: $email, phone: $phone) {
           _id
        avatar
        name
        name_en
        name_gr
        email
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        secretVerified
        emailPreferences
        chatActive
        alerts {
            title
            instance
            frequency
        }
        savedListings
        hasTwoFA
        preferredLanguage
        privileges
    }
  }
`

export const GET_USERS_BY_INTEREST_QUERY = gql`
  query GetUsersByInterestQuery($interestedIn: String, $interestTypes: [String!], $interestRegions: [String!], $interestLocations: [String!]) {
    getUsersByInterest(interestedIn: $interestedIn, interestTypes: $interestTypes, interestRegions: $interestRegions, interestLocations: $interestLocations) {
           _id
        avatar
        name
        name_en
        name_gr
        email
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        secretVerified
        emailPreferences
        chatActive
        alerts {
            title
            instance
            frequency
        }
        savedListings
        hasTwoFA
        preferredLanguage
        privileges
    }
  }
`

export const GET_USER_BY_ID_QUERY = gql`
  query GetUserByIdQuery($userId: String!) {
    getUserById(userId: $userId) {
           _id
        avatar
        name
        name_en
        name_gr
        email
        phone
        interestedIn
        interestTypes
        interestRegions
        interestLocations
        bio
        bio_en
        bio_gr
        secretVerified
        emailPreferences
        chatActive
        alerts {
            title
            instance
            frequency
        }
        savedListings
        hasTwoFA
        preferredLanguage
        privileges
    }
  }
`

export const GET_BROKER_PUBLIC_INFO_QUERY = gql`
  query GetBrokerPublicInfoQuery($brokerId: String!) {
    getBrokerPublicInfo(brokerId: $brokerId) {
        _id
        avatar
        name
        name_en
        name_gr
        email
        phone
        bio
        bio_en
        bio_gr
        privileges
    }
  }
`