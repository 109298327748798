/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a listing model to be used
 * by MST within the store.
 */

import { types } from "mobx-state-tree";
import PriceDate from "./priceDate";
import Coordinates from "./coordinates";
import MediaItem from "./mediaItem";
import ListItemBrokerInfo from "./listItemBrokerInfo";
import MapImageUrl, { defaultMapImageUrlState } from "./mapImageUrl";

const Listing = types.model("Listing", {
  _id: "",
  title: "",
  title_en: "",
  title_gr: "",
  prices: types.maybeNull(types.array(PriceDate, [])),
  currentPrice: types.maybeNull(PriceDate),
  buildingMaterial: "",
  buildingMaterial_en: "",
  buildingMaterial_gr: "",
  yearBuilt: types.number,
  displayOnHomepage: types.boolean,
  propertyType: "",
  propertyType_en: "",
  propertyType_gr: "",
  propertySubType: "",
  propertySubType_en: "",
  propertySubType_gr: "",
  specifics: types.maybeNull(types.array(types.string, [])),
  specifics_en: types.maybeNull(types.array(types.string, [])),
  specifics_gr: types.maybeNull(types.array(types.string, [])),
  listingType: "",
  description: "",
  description_en: "",
  description_gr: "",
  floors: types.number,
  floor: types.number,
  bedrooms: types.number,
  bathrooms: types.number,
  garages: types.number,
  area: types.number,
  yardArea: types.maybeNull(types.number),
  orientation: types.maybeNull(types.array(types.string, [])),
  orientation_en: types.maybeNull(types.array(types.string, [])),
  orientation_gr: types.maybeNull(types.array(types.string, [])),
  media: types.maybeNull(types.array(MediaItem, [])),
  details: types.maybeNull(types.array(types.string, [])),
  details_en: types.maybeNull(types.array(types.string, [])),
  details_gr: types.maybeNull(types.array(types.string, [])),
  coordinates: Coordinates,
  fullAddress: "",
  fullAddress_en: "",
  fullAddress_gr: "",
  region: "",
  region_en: "",
  region_gr: "",
  location: "",
  location_en: "",
  location_gr: "",
  subLocation: types.maybeNull(types.string, ""),
  subLocation_en: types.maybeNull(types.string, ""),
  subLocation_gr: types.maybeNull(types.string, ""),
  country: "",
  country_en: "",
  country_gr: "",
  broker: types.union(types.string, ListItemBrokerInfo), // ID of the broker
  status: "",
  proposedByUser: "", // ID of the proposedByUser
  mapImageUrl: MapImageUrl,
  createdAt: types.maybeNull(types.Date),
  updatedAt: types.maybeNull(types.Date),
});

export const defaultListingState = {
  _id: "",
  title: "",
  title_en: "",
  title_gr: "",
  prices: [],
  currentPrice: {
    price: 0,
    currency: "BGN",
    negotiable: false,
    date: new Date(),
  },
  buildingMaterial: "",
  buildingMaterial_en: "",
  buildingMaterial_gr: "",
  yearBuilt: 0,
  displayOnHomepage: true,
  propertyType: "",
  propertyType_en: "",
  propertyType_gr: "",
  propertySubType: "",
  propertySubType_en: "",
  propertySubType_gr: "",
  specifics: [],
  specifics_en: [],
  specifics_gr: [],
  listingType: "",
  description: "",
  description_en: "",
  description_gr: "",
  floors: 0,
  floor: -1,
  bedrooms: 0,
  bathrooms: 0,
  garages: 0,
  area: 0,
  yardArea: 0,
  orientation: [],
  orientation_en: [],
  orientation_gr: [],
  media: [],
  details: [],
  details_en: [],
  details_gr: [],
  coordinates: { lat: 0, lng: 0 },
  fullAddress: "",
  fullAddress_en: "",
  fullAddress_gr: "",
  region: "",
  region_en: "",
  region_gr: "",
  location: "",
  location_en: "",
  location_gr: "",
  subLocation: "",
  subLocation_en: "",
  subLocation_gr: "",
  country: "",
  country_en: "",
  country_gr: "",
  broker: "", // ID of the broker
  status: "",
  proposedByUser: "", // ID of the proposedByUser
  mapImageUrl: defaultMapImageUrlState,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export default Listing;
