/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines an MediaItem model to be used
 * by MST within the store.
 */

import { types } from "mobx-state-tree";

const MediaItem = types
  .model("Image", {
    url: "",
    publicId: "",
    type: "",
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultMediaItemState = {
  url: "",
  publicId: "",
  type: "",
};

export default MediaItem;
