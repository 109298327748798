/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a ListingStore model to be used
 * by MST within the store.
 */
import { applySnapshot, types } from "mobx-state-tree";
import Listing, { defaultListingState } from "./listing";
import MediaItem from "./mediaItem";
import { not, propEq } from "ramda";

const ListingStore = types
  .model("ListingStore", {
    listingToUpsert: types.maybeNull(Listing),
    listingToCreateMedia: types.maybeNull(types.array(MediaItem, [])),
    shouldOpenDrawer: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setListingToUpsert(listing) {
      applySnapshot(self, {
        ...self,
        listingToUpsert: { ...listing },
      });
    },
    setShouldOpenDrawer(shouldOpen) {
      applySnapshot(self, {
        ...self,
        shouldOpenDrawer: shouldOpen,
      });
    },
    clearListingToUpsert() {
      applySnapshot(self, { ...self, listingToUpsert: defaultListingState });
    },
    setMediaForListingToUpsert(media) {
      return applySnapshot(self, {
        ...self,
        listingToUpsert: {
          ...self.listingToUpsert,
          media: [...media],
        },
      });
    },
    addMediaToListingToUpsert(mediaItem) {
      let currentMediaItems = [];
      let modifiedListingToUpsert = {};

      if (self.listingToUpsert) {
        currentMediaItems = [...self.listingToUpsert.media];
        modifiedListingToUpsert = { ...self.listingToUpsert };
      } else modifiedListingToUpsert = { ...defaultListingState };

      applySnapshot(self, {
        ...self,
        listingToUpsert: {
          ...modifiedListingToUpsert,
          media: [...currentMediaItems, mediaItem],
        },
      });
    },
    removeMediaFromListingToUpsert(publicId) {
      applySnapshot(self, {
        ...self,
        listingToUpsert: {
          ...self.listingToUpsert,
          media: self.listingToUpsert.media.filter((item) =>
            not(propEq(publicId, "publicId")(item))
          ),
        },
      });
    },
    addMediaToListingToCreate(mediaItem) {
      let currentMediaItems = [];

      if (self.listingToCreateMedia) {
        currentMediaItems = [...self.listingToCreateMedia];
      }

      applySnapshot(self, {
        ...self,
        listingToCreateMedia: [...currentMediaItems, mediaItem],
      });
    },
    removeMediaFromListingToCreate(publicId) {
      applySnapshot(self, {
        ...self,
        listingToCreateMedia: [
          ...self.listingToCreateMedia.filter(
            (mediaItem) => mediaItem.publicId !== publicId
          ),
        ],
      });
    },
  }));

export const defaultListingStoreState = {
  listingToUpsert: defaultListingState,
  listingToCreateMedia: [],
  shouldOpenDrawer: false,
};

export default ListingStore;
