/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Message model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import User from "./user";

const Message = types
  .model("Message", {
    _id: "",
    senderName: "",
    senderId: "",
    content: "",
    chatroomId: "",
    createdAt: types.maybeNull(types.union(types.Date, types.string)),
  })
  .actions((self) => ({
    setContent(newContent) {
      applySnapshot(self, { ...self, content: newContent });
    },
  }))
  .views((self) => ({}));

export const defaultMessageState = {
  _id: "",
  senderName: "",
  senderId: "",
  content: "",
  chatroomId: "",
  createdAt: null,
};

export default Message;
