/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a PropertyType model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";

const PropertyType = types
  .model("PropertyType", {
    _id: "",
    name: "",
    name_en: "",
    name_gr: "",
    subTypes: types.maybeNull(types.array(types.string, [])),
    subTypes_en: types.maybeNull(types.array(types.string, [])),
    subTypes_gr: types.maybeNull(types.array(types.string, [])),
    specifics: types.maybeNull(types.array(types.string, [])),
    specifics_en: types.maybeNull(types.array(types.string, [])),
    specifics_gr: types.maybeNull(types.array(types.string, [])),
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultPropertyTypeState = {
  _id: "",
  name: "",
  name_en: "",
  name_gr: "",
  subTypes: [],
  subTypes_en: [],
  subTypes_gr: [],
  specifics: [],
  specifics_en: [],
  specifics_gr: [],
};

export default PropertyType;
