import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { split } from "@apollo/client";

import { authLink } from "./authLink";

// Terminating link which enables file uploads
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_API_WS_URL,
  })
);

/* Apollo Client link to handle incoming queries, mutations and subscriptions
 * using the correct sub-link
 */
export const link = split(
  // If this "test" function returns true
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },

  // We forward the request to the wsLink (handles Subscriptions)
  wsLink,

  // Else to the normal api (which also supports file uploads)
  authLink.concat(uploadLink)
);
