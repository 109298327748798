/*
 * Author: Kristiyan Doykov
 * Last updated: 28/09/2023
 * Purpose: Defines a MapOverlayStore model to be used
 * by MST within the global store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import Coordinates from "./coordinates";

const MapOverlayStore = types
  .model("MapOverlayStore", {
    currentMapCenter: types.maybeNull(Coordinates),
    currentZoom: types.maybeNull(types.number),
    currentOpenPin: types.maybeNull(types.string),
    currentMultiPinIndex: types.maybeNull(types.number),
  })
  .actions((self) => ({
    setCurrentMapCenter(newCenter) {
      applySnapshot(self, { ...self, currentMapCenter: newCenter });
    },
    setCurrentZoom(newZoom) {
      applySnapshot(self, { ...self, currentZoom: newZoom });
    },
    setCurrentOpenPin(newOpenPinId) {
      applySnapshot(self, { ...self, currentOpenPin: newOpenPinId });
    },
    setCurrentMultiPinIndex(newMultiPinIndex) {
      applySnapshot(self, { ...self, currentMultiPinIndex: newMultiPinIndex });
    },
    clear() {
      applySnapshot(self, defaultMapOverlayStoreState);
    },
  }));

export const defaultMapOverlayStoreState = {
  currentMapCenter: null,
  currentZoom: null,
  currentOpenPin: null,
  currentMultiPinIndex: null,
};

export default MapOverlayStore;
