/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a ClientsStore model to be used
 * by MST within the store.
 */

import cloneDeep from "lodash.clonedeep";
import { applySnapshot, types } from "mobx-state-tree";
import { defaultClientState } from "./client";
import Client from "./client";

const ClientsStore = types
  .model("ClientsStore", {
    clientToUpsert: types.maybeNull(Client),
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, { ...defaultClientsStoreState });
    },
    clearClientToUpsert() {
      applySnapshot(self, { ...self, clientToUpsert: defaultClientState });
    },
    setClientToUpsert(client) {
      applySnapshot(self, {
        ...self,
        clientToUpsert: { ...self.clientToUpsert, ...client },
      });
    },
  }))
  .views((self) => ({}));

export const defaultClientsStoreState = {
  clientToUpsert: defaultClientState,
};

export default ClientsStore;
