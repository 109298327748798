/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Client model to be used
 * by MST within the store.
 */

import { types } from "mobx-state-tree";

const Client = types
  .model("Client", {
    _id: "",
    name: " ",
    email: "",
    phone: "",
    birthdate: types.Date,
    interest: "",
    broker: "",
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultClientState = {
  _id: "",
  name: "",
  email: "",
  phone: "",
  birthdate: new Date(),
  interest: "",
  broker: "",
};

export default Client;
