/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a UsersStore model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import User, { defaultUserState } from "./user";

const UsersStore = types
  .model("UsersStore", {
    userToUpsert: types.maybeNull(User),
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, { ...defaultUsersStoreState });
    },
    clearUserToUpsert() {
      applySnapshot(self, { ...self, userToUpsert: defaultUserState });
    },
    setUserToUpsert(user) {
      applySnapshot(self, {
        ...self,
        userToUpsert: { ...self.userToUpsert, ...user },
      });
    },
    setAvatarForUTU(avatar) {
      applySnapshot(self, {
        ...self,
        userToUpsert: {
          ...self.userToUpsert,
          avatar,
        },
      });
    },
  }))
  .views((self) => ({}));

export const defaultUsersStoreState = {
  userToUpsert: defaultUserState,
};

export default UsersStore;
