import { IconArrowUp } from "@tabler/icons-react";
import { useEffect, useState } from "react";

function BackToTopButton() {
  const [visible, setVisible] = useState(false);
  const wrapper = document.querySelector("#root__wrapper");

  const toggleVisible = () =>
    setVisible(document.querySelector("#root__wrapper").scrollTop > 300);

  const scrollToTop = () => {
    if (wrapper)
      wrapper.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    if (wrapper) wrapper.addEventListener("scroll", toggleVisible);
  }, [wrapper]);

  return (
    <div
      className={`back-to-top-button ${
        visible ? "back-to-top-button--visible" : ""
      }`}
      onClick={scrollToTop}
    >
      <IconArrowUp />
    </div>
  );
}

export default BackToTopButton;
