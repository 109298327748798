/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a PropertyTypesStore model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import PropertyType, { defaultPropertyTypeState } from "./propertyType";

const PropertyTypesStore = types
  .model("PropertyTypesStore", {
    propertyTypeToUpsert: types.maybeNull(PropertyType),
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, { ...defaultPropertyTypesStoreState });
    },
    clearPropertyTypeToUpsert() {
      applySnapshot(self, {
        ...self,
        propertyTypeToUpsert: defaultPropertyTypeState,
      });
    },
    setPropertyTypeToUpsert(propertyType) {
      applySnapshot(self, {
        ...self,
        propertyTypeToUpsert: { ...self.propertyTypeToUpsert, ...propertyType },
      });
    },
  }))
  .views((self) => ({}));

export const defaultPropertyTypesStoreState = {
  propertyTypeToUpsert: defaultPropertyTypeState,
};

export default PropertyTypesStore;
