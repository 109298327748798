/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a SearchStore model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";

const Option = types.model("Option", {
  value: "",
  label: "",
});

const SearchStore = types
  .model("SearchStore", {
    // searches: types.maybeNull(types.array(Search, [])),
    currentSearchResults: types.maybeNull(types.array(types.string, [])), // List of result IDs
    latestSearchQuery: types.maybeNull(types.string, ""), // Latest query string
    latestSearchPage: types.maybeNull(types.number, 1), // Latest search page
    latestSearchType: types.maybeNull(types.string, "rent"), // Latest search page
    currentRegion: types.maybe(Option, undefined),
    currentLocationOptions: types.array(Option, []),
    chosenLocations: types.array(Option, []),
    mapOverlayShown: false,
    listingsInCurrentList: types.maybeNull(types.array(types.string, [])),
    noSearchBarOverlay: false,
  })
  .actions((self) => ({
    setCurrentSearchResults(results) {
      applySnapshot(self, { ...self, currentSearchResults: results });
    },
    clearCurrentSearchResults() {
      applySnapshot(self, { ...self, currentSearchResults: [] });
    },
    setListingsInCurrentList(listings) {
      applySnapshot(self, { ...self, listingsInCurrentList: listings });
    },
    clearListingsInCurrentList() {
      applySnapshot(self, { ...self, listingsInCurrentList: [] });
    },
    setLatestSearchQuery(searchQuery) {
      applySnapshot(self, { ...self, latestSearchQuery: searchQuery });
    },
    setLatestSearchPage(pageNumber) {
      applySnapshot(self, { ...self, latestSearchPage: pageNumber });
    },
    setLatestSearchType(listingType) {
      applySnapshot(self, { ...self, latestSearchType: listingType });
    },
    clearLatestSearchQuery() {
      applySnapshot(self, { ...self, latestSearchQuery: null });
    },
    setCurrentRegion(region) {
      applySnapshot(self, { ...self, currentRegion: region });
    },
    setCurrentLocationOptions(options) {
      applySnapshot(self, {
        ...self,
        currentLocationOptions: options,
      });
    },
    setChosenLocations(locations) {
      applySnapshot(self, { ...self, chosenLocations: locations });
    },
    setMapOverlayShown(shown) {
      const root = document.querySelector("#root");
      const rootWrapper = document.querySelector("#root__wrapper");
      const mapOverlay = document.querySelector("#map-overlay");

      if (root && rootWrapper) {
        if (shown) {
          rootWrapper.scrollTo({
            top: 0,
          });
          root.scrollTo({
            top: 0,
          });
          root.classList.add("u-no-scroll");
          rootWrapper.classList.add("u-no-scroll");
        } else {
          root.classList.remove("u-no-scroll");
          rootWrapper.classList.remove("u-no-scroll");
        }
      }
      applySnapshot(self, { ...self, mapOverlayShown: shown });
    },
    setNoSearchBarOverlay(noSearchBar) {
      applySnapshot(self, { ...self, noSearchBarOverlay: noSearchBar });
    },
  }))
  .views((self) => ({}));

export const defaultSearchStoreState = {
  // searches: [],
  currentSearchResults: [],
  latestSearchQuery: "",
  latestSearchPage: 1,
  latestSearchType: "rent",
  currentRegion: undefined,
  currentLocationOptions: [],
  chosenLocations: [],
  mapOverlayShown: false,
  listingsInCurrentList: [],
  noSearchBarOverlay: false,
};

export default SearchStore;
