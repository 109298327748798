/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Component: Footer
 * Purpose: Display the footer, needs
 * to be updated to support links and a
 * form for newsletter subscriptions.
 */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// I18N
import { useTranslation } from "react-i18next";
import {
  PREFFERED_LANG_KEY,
  SESSION_STORAGE_LANG_KEY,
} from "../utils/constants";

import {
  IconAt,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
  IconCopyright,
  IconDeviceMobile,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react";

import { BiWorld } from "react-icons/bi";

function Footer() {
  // I18N
  const {
    t,
    i18n: { resolvedLanguage = "bg", changeLanguage },
  } = useTranslation(["common", "translation"]);

  const common = (phrase) => t(phrase, { ns: "common" });
  const translation = (phrase) => t(phrase, { ns: "translation" });

  const lngs = {
    bg: { nativeName: "Български" },
    en: { nativeName: "English" },
    gr: { nativeName: "Ελληνική" },
  };

  const preferredLang =
    window.navigator.userLanguage || window.navigator.language;

  useEffect(() => {
    if (preferredLang) {
      const current = sessionStorage.getItem(PREFFERED_LANG_KEY);
      const newLang = preferredLang.slice(0, 2);
      if (current !== newLang)
        sessionStorage.setItem(PREFFERED_LANG_KEY, newLang);
    }
  }, [preferredLang]);

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content__middle">
          <div className="footer__content__middle__columns">
            <div className="footer__content__middle__column">
              <h4 className="footer__content__middle__column__heading">
                {translation("contactWays")}
              </h4>
              <p className="footer__content__middle__column__item">
                <span className="footer__content__middle__column__item__bold">
                  <IconMapPin className="footer__icon" />
                  {translation("address")}
                </span>
                <span className="footer__content__middle__column__item__text">
                  {translation("actualAddress")
                    .split("\n")
                    .map((line) => (
                      <span
                        style={{ display: "block", marginLeft: "0.5rem" }}
                        key={line}
                      >
                        {line}
                      </span>
                    ))}
                </span>
              </p>
              <p className="footer__content__middle__column__item">
                <span className="footer__content__middle__column__item__bold">
                  <IconPhone className="footer__icon" />
                  {common("landline")}
                </span>
                <a
                  href="tel:080090050"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  080090050
                </a>
              </p>
              <p className="footer__content__middle__column__item">
                <span className="footer__content__middle__column__item__bold">
                  <IconDeviceMobile className="footer__icon" />
                  {translation("mobilePhone")}
                </span>
                <span>
                  <a
                    href="tel:0895662129"
                    className="link lin--white footer__content__middle__column__item__link"
                  >
                    0895662129
                  </a>
                  ,
                  <a
                    href="tel:0894689736"
                    className="link lin--white footer__content__middle__column__item__link"
                  >
                    0894689736
                  </a>
                </span>
              </p>
              <p className="footer__content__middle__column__item">
                <span className="footer__content__middle__column__item__bold">
                  <IconAt className="footer__icon" />
                  {common("email")}
                </span>
                <a
                  href="mailto:office@aconsult-bg.com"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  office@aconsult-bg.com
                </a>
              </p>
            </div>
            <div className="footer__content__middle__column">
              <h4 className="footer__content__middle__column__heading">
                {translation("information")}
              </h4>
              <p className="footer__content__middle__column__item">
                {translation("informationDisclosure")}
              </p>
              <p className="footer__content__middle__column__item">
                <a
                  href={`https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=${
                    resolvedLanguage === "gr"
                      ? "EL"
                      : resolvedLanguage.toUpperCase()
                  }`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  {translation("eprsSite")}
                </a>
              </p>
              <p className="footer__content__middle__column__item">
                <Link
                  to="/общи-условия"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  {translation("t&c")}
                </Link>
              </p>
              <p className="footer__content__middle__column__item">
                <Link
                  to="/бисквитки"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  {translation("cookiePolicy")}
                </Link>
              </p>
            </div>
            <div className="footer__content__middle__column">
              <h4 className="footer__content__middle__column__heading">
                {translation("useful")}
              </h4>
              <p className="footer__content__middle__column__item">
                <Link
                  to="/съвети-за-продавачи"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  {translation("sellerTips")}
                </Link>
              </p>
              <p className="footer__content__middle__column__item">
                <Link
                  to="/съвети-за-купувачи"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  {translation("buyerTips")}
                </Link>
              </p>
              <p className="footer__content__middle__column__item">
                <Link
                  to="/съвети-за-наематели"
                  className="link lin--white footer__content__middle__column__item__link"
                >
                  {translation("renterTips")}
                </Link>
              </p>
            </div>
          </div>
          <div className="footer__content__middle__socials__wrapper">
            <div className="footer__content__middle__socials__inner">
              <h5 className="footer__content__middle__socials__heading">
                {translation("followUs")}
              </h5>
              <div className="footer__content__middle__socials">
                <Link
                  to="https://www.facebook.com/pages/A-Consult-Ltd/523291287724280"
                  target="_blank"
                >
                  <IconBrandFacebook className="footer__content__middle__socials__icon" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/a-consult-ltd-/about/"
                  target="_blank"
                >
                  <IconBrandLinkedin className="footer__content__middle__socials__icon" />
                </Link>
                <Link
                  to="https://www.instagram.com/amicaconsult/"
                  target="_blank"
                >
                  <IconBrandInstagram className="footer__content__middle__socials__icon" />
                </Link>
                <Link
                  to="https://www.youtube.com/user/aconsultrealestates/"
                  target="_blank"
                >
                  <IconBrandYoutube className="footer__content__middle__socials__icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__content__bottom-row"></div>
      </div>
      <div className="footer__image__wrapper">
        <img
          className="footer__image"
          src="https://res.cloudinary.com/drtutht80/image/upload/f_auto/v1692002835/amica-others/footer_bg.png"
          alt="City Landscape Background"
        />
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom__content">
          <p className="footer__copy">
            {translation("amicaConsult")}{" "}
            <IconCopyright className="footer__icon" />{" "}
            {new Date().getFullYear()}
          </p>
          <div className="footer__language-switch">
            <BiWorld />
            <select
              aria-label="language switch"
              value={resolvedLanguage}
              className="footer__language-switch__options"
              onChange={({ target: { value } }) => {
                sessionStorage.setItem(SESSION_STORAGE_LANG_KEY, value);
                changeLanguage(value);
              }}
            >
              {Object.keys(lngs).map((lng) => (
                <option
                  key={lng}
                  value={lng}
                  label={lngs[lng].nativeName}
                  className="footer__language-switch__option"
                >
                  {lngs[lng].nativeName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
