/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Component: Loader
 * Purpose: Displays a spinner in the
 * center of its context.
 */
import React from "react";
import loader from "../assets/loader.svg";

export default function Loader({ noMargin, fullHeight }) {
  return (
    <div className={`loader ${fullHeight ? "loader--full-height" : ""}`}>
      <img
        className={`loader__spinner ${
          noMargin ? "loader__spinner--no-margin" : ""
        }`}
        src={loader}
        alt="Animated Spinning Loader"
      />
    </div>
  );
}
