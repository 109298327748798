/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a RegionsStore model to be used
 * by MST within the store.
 */

import cloneDeep from "lodash.clonedeep";
import { applySnapshot, types } from "mobx-state-tree";
import Region, { defaultRegionState } from "./region";

const RegionsStore = types
  .model("RegionsStore", {
    regionToUpsert: types.maybeNull(Region),
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, { ...defaultRegionsStoreState });
    },
    clearRegionToUpsert() {
      applySnapshot(self, { ...self, regionToUpsert: defaultRegionState });
    },
    setRegionToUpsert(region) {
      applySnapshot(self, {
        ...self,
        regionToUpsert: { ...self.regionToUpsert, ...region },
      });
    },
  }))
  .views((self) => ({}));

export const defaultRegionsStoreState = {
  regionToUpsert: defaultRegionState,
};

export default RegionsStore;
