/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Login State model to be used
 * by MST within the store. Can be used to store
 * information that needs to be available between
 * refreshes such as a storedUser while waiting for
 * a 6-digit authenticator code.
 */

import { types, applySnapshot } from "mobx-state-tree";
import User, { defaultUserState } from "./user";

const LoginState = types
  .model("LoginStateModel", {
    storedUser: User,
    storedRefreshToken: "",
    wrongCode: types.optional(types.boolean, false),
    loginHasRun: types.optional(types.boolean, false),
    loading: false,
    lastKnownUrl: types.maybeNull(types.maybe(types.string, "")),
    // TODO: storedAvatar: ""  Use this to hold the avatar while it's being uploaded (editing)
  })
  .actions((self) => ({
    setLoginState(newloginState) {
      applySnapshot(self, { ...self, ...newloginState });
    },
    clear() {
      applySnapshot(self, { ...defaultLoginState });
    },
    setLastKnownUrl(lastKnownUrl) {
      applySnapshot(self, {
        ...self,
        lastKnownUrl,
      });
    },
  }));

export const defaultLoginState = {
  storedUser: { ...defaultUserState, sessionToken: "" },
  storedRefreshToken: "",
  wrongCode: false,
  loginHasRun: false,
  loading: false,
  lastKnownUrl: "",
};

export default LoginState;
