/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines the main application
 * router which defines the front-end routing
 * system.
 */

// React & React Router
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";

// Apollo
import { useLazyQuery, useMutation } from "@apollo/client";

// MobX
import { inject, observer } from "mobx-react";

import {
  and,
  anyPass,
  equals,
  find,
  gt,
  includes,
  isEmpty,
  isNil,
  map,
  not,
  prop,
  propOr,
} from "ramda";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { configureAbly } from "@ably-labs/react-hooks";

import { ToastContainer, toast } from "react-toastify";

// Global styles
import "react-toastify/dist/ReactToastify.css";
import "react-modern-drawer/dist/index.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";

// Custom hooks
import { useWindowSize } from "../hooks/hooks";

// Regular components
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import BackToTopButton from "../components/BackToTopButton";

// Queries
import { CHECK_AUTH_QUERY } from "../queries/user";

// Constants
import {
  REFRESH_TOKEN,
  SESSION_STORAGE_LANG_KEY,
  SESSION_TOKEN,
  WIDTH_FOR_MOBILE,
  WIDTH_FOR_MOBILE_GREEK_SPECIAL_CASE,
} from "../utils/constants";

import { checkErrorAndAction, decrypt, encrypt } from "../utils/utils";
import {
  SET_CHAT_ACTIVE_MUTATION,
  SET_CHAT_INACTIVE_MUTATION,
} from "../mutations/user";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
} from "@tabler/icons-react";
import Suspended from "../components/Suspended";
import { useCloudinaryScript } from "../hooks/useCloudinaryScript";

const MapOverlay = lazy(() => import("../components/MapOverlay"));

// Lazy-loaded components
const Login = lazy(() => import("../components/Login"));
const ForgottenPassword = lazy(() => import("../components/ForgottenPassword"));
const ResetPassword = lazy(() => import("../components/ResetPassword"));
const PrivateRoute = lazy(() => import("../components/PrivateRoute"));
const Admin = lazy(() => import("../pages/Admin"));
const NotFound = lazy(() => import("../components/NotFound"));
const HomePage = lazy(() => import("../pages/HomePage"));
const ChatPage = lazy(() => import("../pages/ChatPage"));
const ListingPage = lazy(() => import("../pages/ListingPage"));
const Navigation = lazy(() => import("../components/Navigation"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const ResultsPage = lazy(() => import("../pages/ResultsPage"));
const TeamPage = lazy(() => import("../pages/TeamPage"));
const BrokerPage = lazy(() => import("../pages/BrokerPage"));
const AddListing = lazy(() => import("../pages/AddListing"));
const ProposeListingPage = lazy(() => import("../pages/ProposeListingPage"));
const RemoveUserFromAlert = lazy(() => import("../pages/RemoveUserFromAlert"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("../pages/CookiePolicy"));
const TipsForSellers = lazy(() => import("../pages/TipsForSellers"));
const TipsForBuyers = lazy(() => import("../pages/TipsForBuyers"));
const TipsForTenants = lazy(() => import("../pages/TipsForTenants"));
const ReferralPage = lazy(() => import("../pages/ReferralPage"));
const ContactPage = lazy(() => import("../pages/ContactPage"));
const Header = lazy(() => import("../components/header/index"));
const AdminHeader = lazy(() => import("../components/AdminHeader"));

configureAbly({ key: process.env.REACT_APP_ABLY_API_KEY });

function AppRouter({ rootStore }) {
  const user = rootStore.user;
  const searches = rootStore.searches;
  const mapOverlay = rootStore.mapOverlay;
  const { mapOverlayShown } = searches;
  const {
    currentMapCenter,
    currentZoom,
    currentOpenPin,
    currentMultiPinIndex,
  } = mapOverlay;

  const overlayWasLastOpen =
    currentMapCenter ||
    currentZoom ||
    currentOpenPin ||
    not(isNil(currentMultiPinIndex));

  const { loginState } = rootStore;

  const [width] = useWindowSize();

  const navigate = useNavigate();
  const {
    t,
    i18n: { resolvedLanguage = "bg" },
  } = useTranslation(["common", "translation"]);

  const translation = (phrase) => t(phrase, { ns: "translation" });

  const location = useLocation();

  const [listingIds, setListingIds] = useState(searches.currentSearchResults);

  useEffect(() => {
    setListingIds(searches.currentSearchResults);
  }, [JSON.stringify(searches.currentSearchResults)]);

  const path = location.pathname;

  const adminSite =
    rootStore.currentComponent !== "" &&
    includes(decodeURIComponent(path).toLowerCase(), [
      "/admin",
      "/админ",
      "/chat",
      "/чат",
    ]);

  const wrapper = document.querySelector("#root__wrapper");

  const scrollToTop = () => {
    if (wrapper)
      wrapper.scrollTo({
        top: 0,
        // behavior: "smooth",
      });
  };

  useEffect(() => {
    scrollToTop();

    if (
      includes(decodeURIComponent(path).toLowerCase(), [
        "/admin",
        "/админ",
        "/chat",
        "/чат",
      ])
    ) {
      sessionStorage.setItem(SESSION_STORAGE_LANG_KEY, "bg");
      changeLanguage("bg");
    }

    if (
      not(
        new RegExp(/\/обява|\/listing|\/търсене|\/search/s).test(
          decodeURIComponent(path).toLowerCase()
        )
      )
    ) {
      mapOverlay.clear();
    }
  }, [path]);

  const [checkAuth, { loading }] = useLazyQuery(CHECK_AUTH_QUERY, {
    fetchPolicy: "network-only",
    onCompleted({ authenticate }) {
      // Save the JWT in localStorage
      if (authenticate) {
        if (authenticate.sessionHeaderToken) {
          localStorage.setItem(
            SESSION_TOKEN,
            encrypt(authenticate.sessionHeaderToken.body)
          );
        }

        // Initiate the user in state
        user.setUser({
          ...authenticate.user,
          sessionToken: authenticate.sessionHeaderToken
            ? authenticate.sessionHeaderToken.body
            : decrypt(localStorage.getItem(SESSION_TOKEN))
            ? decrypt(localStorage.getItem(SESSION_TOKEN))
            : null,
          savedListingIds: [...authenticate.user.savedListings],

          alertIds: [
            ...authenticate.user.alerts.map((alert) => alert.instance),
          ],
        });
      }
    },
    onError: async (error) => {
      console.error(error);
      const lastKnownUrl = `${window.location.pathname}${window.location.search}`;
      user.logout();
      loginState.setLoginState({ loading: false, loginHasRun: false });
      loginState.setLastKnownUrl(lastKnownUrl);
      navigate("/вход");
    },
  });

  useEffect(() => {
    if (navigator.userAgent.indexOf("iPhone") > -1) {
      document
        .querySelector("[name=viewport]")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1"
        );
    }
  }, []);

  useEffect(() => {
    const refreshToken = decrypt(localStorage.getItem(REFRESH_TOKEN));
    const sessionToken = decrypt(localStorage.getItem(SESSION_TOKEN));

    const possibleUserSigns = [sessionToken, refreshToken];

    const worthSending = anyPass([
      (sign) => not(isNil(sign)),
      (sign) => not(isEmpty(sign)),
    ]);

    if (find(worthSending, possibleUserSigns)) checkAuth();
  }, [
    prop("_id", user),
    localStorage.getItem(REFRESH_TOKEN),
    localStorage.getItem(SESSION_TOKEN),
  ]);

  // This is to avoid the re-render after login from preventing the toast from appearing
  // We save it to sessionStorage in Login.js and then display after the dust has settled
  useEffect(() => {
    if (not(loading)) {
      const toastMessage = sessionStorage.getItem("showSuccessToast");

      if (toastMessage) {
        toast(toastMessage, { type: "success" });

        sessionStorage.removeItem("showSuccessToast");
      }
    }
  }, [loading]);

  useEffect(() => {
    return () => {
      searches.setMapOverlayShown(false);
      mapOverlay.clear();
    };
  }, []);

  useEffect(() => {
    const cleanup = () => {
      searches.setMapOverlayShown(false);
      mapOverlay.clear();
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  // Set user's chatActive to true and to false when leaving
  const [setChatActive] = useMutation(SET_CHAT_ACTIVE_MUTATION, {
    onCompleted: () => {
      user.setUser({
        ...user,
        chatActive: true,
      });
    },
    onError: async (error) => {
      console.error(error);
      await checkErrorAndAction({
        errorMessage: error.message,
        humanizedMessage: error.networkError?.result,
        userStore: user,
        loginStore: loginState,
        navigate,
        translation,
      });
    },
  });
  // Set user's chatActive to true and to false when leaving
  const [setChatInactive] = useMutation(SET_CHAT_INACTIVE_MUTATION, {
    onCompleted: () => {
      user.setUser({
        ...user,
        chatActive: false,
      });
    },
    onError: async (error) => {
      console.error(error);
      await checkErrorAndAction({
        errorMessage: error.message,
        humanizedMessage: error.networkError?.result,
        userStore: user,
        loginStore: loginState,
        navigate,
        translation,
      });
    },
  });

  useEffect(() => {
    if (
      prop("_id", user) &&
      includes(prop("privileges", user), ["admin", "broker"])
    ) {
      if (
        includes(decodeURIComponent(location.pathname), ["/chat", "/чат"]) &&
        equals(prop("chatActive", user), false)
      )
        setChatActive();
      if (
        !includes(decodeURIComponent(location.pathname), ["/chat", "/чат"]) &&
        equals(prop("chatActive", user), true)
      )
        setChatInactive();
    }
  }, [prop("_id", user), location.pathname]);

  // Enable the user's "online" status when they come on to the chat page

  const overlayShown =
    mapOverlayShown ||
    (new RegExp(/\/търсене|\/search/s).test(
      decodeURIComponent(path).toLowerCase()
    ) &&
      overlayWasLastOpen);

  const specialCaseMenuBarGreek = and(
    equals(resolvedLanguage, "gr"),
    not(propOr(false, "_id", user))
  );

  // ---------------------- Cache Busting -----------------------------

  const refreshCacheAndReload = async () => {
    console.log("[ Cache Busting ] Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      const cacheNames = await caches.keys();

      await Promise.all(map((name) => caches.delete(name), cacheNames));
    }
    toast(translation("cacheBusting.willRestart"), { type: "info" });

    // Warn the user
    setTimeout(() => {
      // delete browser cache and hard reload
      window.location.reload(true);
    }, 1500);
  };

  useEffect(() => {
    fetch("/meta.json")
      .then((res) => res.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        if (latestVersion && currentVersion) {
          const shouldForceRefresh = gt(latestVersion, currentVersion);
          if (shouldForceRefresh) {
            console.log(
              `[ Cache Busting ] We have a new version - ${latestVersion}. Should force refresh.`
            );
            (async () => {
              await refreshCacheAndReload();
            })();
          }
        }
      })
      .catch((err) => {
        console.error("[ Cache Busting ] Failed to fetch meta.json.", err);
      });
  }, []);

  // ---------------------- Cache Busting End -----------------------------

  // Load Cloudinary later on, to avoid blocking
  useCloudinaryScript();

  return loading ? (
    <Loader />
  ) : (
    <div
      id="root__wrapper"
      className={`root__wrapper ${adminSite ? "root__wrapper--grey" : ""} ${
        overlayShown ? "u-no-scroll" : ""
      }`}
    >
      {not(adminSite) && width <= 600 && (
        <div className="social-media-bar">
          <p className="social-media-bar__text">{translation("followUs")}</p>
          <div className="social-media-bar__icons">
            <Link
              aria-label="Follow us on Facebook"
              className="social-media-bar__item social-media-bar__item--fb"
              to="https://www.facebook.com/pages/A-Consult-Ltd/523291287724280"
              target="_blank"
            >
              <IconBrandFacebook className="social-media-bar__item__icon" />
            </Link>
            <Link
              aria-label="Follow us on Instagram"
              to="https://www.instagram.com/amicaconsult/"
              className="social-media-bar__item social-media-bar__item--ig"
              target="_blank"
            >
              <IconBrandInstagram className="social-media-bar__item__icon" />
            </Link>
            <Link
              aria-label="Follow us on LinkedIn"
              to="https://www.linkedin.com/company/a-consult-ltd-/about/"
              target="_blank"
              className="social-media-bar__item social-media-bar__item--linkedin"
            >
              <IconBrandLinkedin className="social-media-bar__item__icon" />
            </Link>
            <Link
              aria-label="Follow us on YouTube"
              className="social-media-bar__item social-media-bar__item--yt"
              to="https://www.youtube.com/user/aconsultrealestates/"
              target="_blank"
            >
              <IconBrandYoutube className="social-media-bar__item__icon" />
            </Link>
          </div>
        </div>
      )}
      {adminSite ||
      width <
        (specialCaseMenuBarGreek
          ? WIDTH_FOR_MOBILE_GREEK_SPECIAL_CASE
          : WIDTH_FOR_MOBILE) ? null : (
        <div className="header-placeholder">&nbsp;</div>
      )}
      <Suspense fallback={<Loader />}>
        {includes(decodeURIComponent(location.pathname), ["/chat", "/чат"]) ? (
          <AdminHeader />
        ) : adminSite ? null : (
          <Header />
        )}
      </Suspense>
      {!adminSite ? <Navigation /> : null}
      <ToastContainer
        position={width < WIDTH_FOR_MOBILE ? "top-center" : "bottom-right"}
        style={{ zIndex: 99999999 }}
      />
      <Suspended>
        {overlayShown && <MapOverlay clientSideList listingIds={listingIds} />}
      </Suspended>
      <div className={`${!adminSite ? "w65" : "admin-site__wrapper"}`}>
        {not(adminSite) && width > 600 && (
          <div className="social-media-bar__container">
            <div className="social-media-bar">
              <Link
                aria-label="Follow us on Facebook"
                className="social-media-bar__item social-media-bar__item--fb"
                to="https://www.facebook.com/pages/A-Consult-Ltd/523291287724280"
                target="_blank"
              >
                <IconBrandFacebook className="social-media-bar__item__icon" />
              </Link>
              <Link
                aria-label="Follow us on Instagram"
                to="https://www.instagram.com/amicaconsult/"
                className="social-media-bar__item social-media-bar__item--ig"
                target="_blank"
              >
                <IconBrandInstagram className="social-media-bar__item__icon" />
              </Link>
              <Link
                aria-label="Follow us on LinkedIn"
                to="https://www.linkedin.com/company/a-consult-ltd-/about/"
                target="_blank"
                className="social-media-bar__item social-media-bar__item--linkedin"
              >
                <IconBrandLinkedin className="social-media-bar__item__icon" />
              </Link>
              <Link
                aria-label="Follow us on YouTube"
                className="social-media-bar__item social-media-bar__item--yt"
                to="https://www.youtube.com/user/aconsultrealestates/"
                target="_blank"
              >
                <IconBrandYoutube className="social-media-bar__item__icon" />
              </Link>
            </div>
          </div>
        )}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/search/:listingType/:page?"
              element={<ResultsPage />}
            />
            <Route
              path="/търсене/:listingType/:page?"
              element={<ResultsPage />}
            />
            <Route
              path="/profile/account"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"account"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/моят/акаунт"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"account"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/listings"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"listings"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/моите/обяви"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"listings"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/saved-listings"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"saved"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/моите/запазени-обяви"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"saved"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/searches"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"searches"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/моите/търсения"
              element={
                <PrivateRoute>
                  <ProfilePage tabToSet={"searches"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-listing"
              element={
                <PrivateRoute>
                  <AddListing />
                </PrivateRoute>
              }
            />
            <Route
              path="/добави-обява"
              element={
                <PrivateRoute>
                  <AddListing />
                </PrivateRoute>
              }
            />
            <Route path="/propose-listing" element={<ProposeListingPage />} />
            <Route path="/предложи-имот" element={<ProposeListingPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/екип" element={<TeamPage />} />
            <Route path="/referral" element={<ReferralPage />} />
            <Route path="/референция" element={<ReferralPage />} />
            <Route path="/broker/:id" element={<BrokerPage />} />
            <Route path="/брокер/:id" element={<BrokerPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/вход" element={<Login />} />
            <Route path="/signup" element={<Login />} />
            <Route path="/регистрация" element={<Login />} />
            <Route
              path="/unsubscribe-from-alert/:alertId"
              element={
                <PrivateRoute>
                  <RemoveUserFromAlert />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute adminRoute={true}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/админ"
              element={
                <PrivateRoute adminRoute={true}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <PrivateRoute adminRoute={true}>
                  <ChatPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/чат"
              element={
                <PrivateRoute adminRoute={true}>
                  <ChatPage />
                </PrivateRoute>
              }
            />

            <Route path="/listing/:_id" element={<ListingPage />} />
            <Route path="/обява/:_id" element={<ListingPage />} />
            <Route path="/forgottenPassword" element={<ForgottenPassword />} />
            <Route path="/забравена-парола" element={<ForgottenPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route path="/contact" element={<ContactPage />} />
            <Route path="/контакти" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/общи-условия" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<CookiePolicy />} />
            <Route path="/бисквитки" element={<CookiePolicy />} />
            <Route path="/tips-for-sellers" element={<TipsForSellers />} />
            <Route path="/съвети-за-продавачи" element={<TipsForSellers />} />
            <Route path="/tips-for-buyers" element={<TipsForBuyers />} />
            <Route path="/съвети-за-купувачи" element={<TipsForBuyers />} />
            <Route path="/tips-for-tenants" element={<TipsForTenants />} />
            <Route path="/съвети-за-наематели" element={<TipsForTenants />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
      {!adminSite ? <Footer /> : null}
      {!adminSite ? <BackToTopButton /> : null}
    </div>
  );
}

export default inject(({ rootStore }) => ({ rootStore }))(observer(AppRouter));
