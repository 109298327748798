/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a AdminStore model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";
import { functionType } from "./custom_types/functionType";

const AdminStore = types
  .model("AdminStore", {
    currentScreen: "",
    settingsPopupOpen: false,
    transferPopupOpen: false,
    transferPopupDeleting: false,
    transferPopupRemoveFromListCb: types.maybe(
      types.maybeNull(functionType, null)
    ),
    transferPopupCouldAbortChange: false,
    transferPopupAbortChange: false,
    brokerIdToTransferFrom: "",
    chatDrawerOpen: false,
  })
  .actions((self) => ({
    setCurrentScreen(newScreen) {
      applySnapshot(self, {
        ...self,
        currentScreen: newScreen,
      });
    },
    clearCurrentScreen() {
      applySnapshot(self, {
        ...self,
        currentScreen: "",
      });
    },
    setSettingsPopupOpen(open) {
      applySnapshot(self, {
        ...self,
        settingsPopupOpen: open,
      });
    },
    setTransferPopupOpen(open) {
      applySnapshot(self, {
        ...self,
        transferPopupOpen: open,
      });
    },
    setTransferPopupAbortChange(transferPopupAbortChange) {
      applySnapshot(self, {
        ...self,
        transferPopupAbortChange,
      });
    },
    setTransferPopupCouldAbortChange(transferPopupCouldAbortChange) {
      applySnapshot(self, {
        ...self,
        transferPopupCouldAbortChange,
      });
    },
    setTransferPopupDeleting(
      transferPopupDeleting,
      transferPopupRemoveFromListCb
    ) {
      applySnapshot(self, {
        ...self,
        transferPopupDeleting,
        transferPopupRemoveFromListCb,
      });
    },
    setBrokerIdToTransferFrom(id) {
      applySnapshot(self, {
        ...self,
        brokerIdToTransferFrom: id,
      });
    },
    reset() {
      applySnapshot(self, { ...defaultAdminStoreState });
    },
    setChatDrawerOpen(chatDrawerOpen) {
      applySnapshot(self, { ...self, chatDrawerOpen });
    },
  }))
  .views((self) => ({}));

export const defaultAdminStoreState = {
  currentScreen: "",
  settingsPopupOpen: false,
  staff: [],
  transferPopupOpen: false,
  transferPopupDeleting: false,
  transferPopupRemoveFromListCb: null,
  transferPopupCouldAbortChange: false,
  transferPopupAbortChange: false,
  brokerIdToTransferFrom: "",
  chatDrawerOpen: false,
};

export default AdminStore;
