/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: To setup the Root store by either
 * reusing a saved instance in localStorage or
 * creating a new one with default values for
 * each sub-store.
 */
import { onSnapshot } from "mobx-state-tree";
import { RootStore } from "./store";
import { defaultUserState } from "../models/user";
import { defaultLoginState } from "../models/loginState";
import { defaultListingStoreState } from "../models/listingStore";
import { defaultSearchStoreState } from "../models/searchStore";
import { defaultChatStoreState } from "../models/chatStore";
import { defaultAdminStoreState } from "../models/adminStore";
import { cast } from "mobx-state-tree";
import { defaultClientsStoreState } from "../models/clientsStore";
import { defaultRegionsStoreState } from "../models/regionsStore";
import { defaultPropertyTypesStoreState } from "../models/propertyTypesStore";
import { defaultUsersStoreState } from "../models/usersStore";
import { defaultMapOverlayStoreState } from "../models/mapOverlayStore";
import { decrypt, encrypt } from "../utils/utils";
import { cloneDeep } from "lodash";

const isLocal = process.env.REACT_APP_LOCAL === "true";

const defaultRootStore = () =>
  RootStore.create({
    user: defaultUserState,
    loginState: defaultLoginState,
    listings: defaultListingStoreState,
    searches: defaultSearchStoreState,
    mapOverlay: defaultMapOverlayStoreState,
    chat: defaultChatStoreState,
    currentComponent: "",
    adminStore: defaultAdminStoreState,
    clientsStore: defaultClientsStoreState,
    regionsStore: defaultRegionsStoreState,
    propertyTypesStore: defaultPropertyTypesStoreState,
    usersStore: defaultUsersStoreState,
  });

export const setupRootStore = () => {
  const snapshot = JSON.parse(localStorage.getItem("latestSnapshot"));
  const userSnapshot = localStorage.getItem("userSnapshot");
  const adminSnapshot = localStorage.getItem("adminSnapshot");
  const loginStateSnapshot = localStorage.getItem("loginStateSnapshot");

  // To backfill for users who had used the site prior to encryption
  if (!userSnapshot || !adminSnapshot || !loginStateSnapshot)
    localStorage.clear();

  let _rootStore;

  if (!snapshot) {
    if (isLocal) console.log("Creating new root store");
    _rootStore = defaultRootStore();
    if (isLocal) console.log("New root store created");
  } else {
    let finalSnapshot = {
      ...snapshot,
    };
    if (userSnapshot) {
      let decryptedUserStore;
      try {
        decryptedUserStore = decrypt(userSnapshot, false);
        if (!decryptedUserStore) decryptedUserStore = defaultUsersStoreState;
      } catch (error) {
        if (isLocal)
          console.error("Failed decrypt userSnapshot", userSnapshot, error);
        decryptedUserStore = defaultUsersStoreState;
      }

      finalSnapshot = {
        ...finalSnapshot,
        user: decryptedUserStore,
      };
    } else {
      finalSnapshot = {
        ...finalSnapshot,
        user: defaultUsersStoreState,
      };
    }
    if (adminSnapshot) {
      let decryptedAdminStore;
      try {
        decryptedAdminStore = decrypt(adminSnapshot, false);
        if (!decryptedAdminStore) decryptedAdminStore = defaultAdminStoreState;
      } catch (error) {
        if (isLocal)
          console.error("Failed decrypt adminSnapshot", adminSnapshot, error);
        decryptedAdminStore = defaultAdminStoreState;
      }

      finalSnapshot = {
        ...finalSnapshot,
        adminStore: decryptedAdminStore,
      };
    } else {
      finalSnapshot = {
        ...finalSnapshot,
        adminStore: defaultAdminStoreState,
      };
    }
    if (loginStateSnapshot) {
      let decryptedLoginStore;
      try {
        decryptedLoginStore = decrypt(loginStateSnapshot, false);
        if (!decryptedLoginStore) decryptedLoginStore = defaultLoginState;
      } catch (error) {
        if (isLocal)
          console.error(
            "Failed decrypt loginStateSnapshot",
            loginStateSnapshot,
            error
          );
        decryptedLoginStore = defaultLoginState;
      }

      finalSnapshot = {
        ...finalSnapshot,
        loginState: decryptedLoginStore,
      };
    } else {
      finalSnapshot = {
        ...finalSnapshot,
        loginState: defaultLoginState,
      };
    }

    _rootStore = RootStore.create(cast(finalSnapshot));
  }

  onSnapshot(_rootStore, (snapshot) => {
    if (isLocal) console.log("New Snapshot: ", snapshot);
    const userStore = cloneDeep(snapshot.user);
    const adminStore = cloneDeep(snapshot.adminStore);
    const loginStore = cloneDeep(snapshot.loginState);
    const snapshotClone = cloneDeep(snapshot);
    delete snapshotClone.user;
    delete snapshotClone.adminStore;
    delete snapshotClone.loginState;
    const encryptedUserStore = encrypt(userStore, false);
    const encryptedAdminStore = encrypt(adminStore, false);
    const encryptedLoginStore = encrypt(loginStore, false);

    localStorage.setItem("latestSnapshot", JSON.stringify(snapshotClone));
    localStorage.setItem("userSnapshot", encryptedUserStore);
    localStorage.setItem("adminSnapshot", encryptedAdminStore);
    localStorage.setItem("loginStateSnapshot", encryptedLoginStore);
  });

  return _rootStore;
};
