/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines a Coordinates model to be used
 * by MST within the store.
 */

import { applySnapshot, types } from "mobx-state-tree";

const Coordinates = types
  .model("Coordinates", {
    lat: types.number,
    lng: types.number,
  })
  .actions((self) => ({}))
  .views((self) => ({}));

export const defaultCoordinatesState = {
  lat: 0.0,
  lng: 0.0,
};

export default Coordinates;
