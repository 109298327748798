/*
 * Author: Kristiyan Doykov
 * Last updated: 14/12/2021
 * Purpose: Defines the Root MST store.
 */
import { types } from "mobx-state-tree";
import User from "../models/user";
import LoginState from "../models/loginState";
import ListingStore from "../models/listingStore";
import SearchStore from "../models/searchStore";
import ChatStore from "../models/chatStore";
import { applySnapshot } from "mobx-state-tree";
import AdminStore from "../models/adminStore";
import ClientsStore from "../models/clientsStore";
import RegionsStore from "../models/regionsStore";
import PropertyTypesStore from "../models/propertyTypesStore";
import UsersStore from "../models/usersStore";
import MapOverlayStore from "../models/mapOverlayStore";

export const RootStore = types
  .model("Root", {
    user: User,
    loginState: LoginState,
    listings: ListingStore,
    mapOverlay: MapOverlayStore,
    searches: SearchStore,
    chat: ChatStore,
    currentComponent: "",
    adminStore: AdminStore,
    clientsStore: ClientsStore,
    regionsStore: RegionsStore,
    propertyTypesStore: PropertyTypesStore,
    usersStore: UsersStore,
  })
  .actions((self) => ({
    setUser(user) {
      return self.user.setUser(user);
    },
    setCurrentComponent(component) {
      applySnapshot(self, { ...self, currentComponent: component });
    },
  }));
